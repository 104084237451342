import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Title } from '../Typography';

const MaskContainerTopTw = tw.div`
  curve-mask-inverted
  inverted
  w-full
`;
export const Container = styled.div`
  .svg-product {
    z-index: -1;
    path {
      fill: #ffffff;
    }
  }
`;
export const ContainerStyle =
  'bg-bg-light-gray md:pt-32 md:pb-10 sm:pt-20 sm:pb-0 sm:mb-10 md:mb-0 -mt-20 relative w-full md:mb-36';

export const MaskContainerTop = styled(MaskContainerTopTw as any)<{ $curveColor?: any }>`
  background-color: ${(props) => props.$curveColor || '#000'};
  padding-bottom: 25px;
  overflow: hidden;
  z-index: 10;
  position: relative;
  @media (min-width: 768px) {
    padding-bottom: 35px;
  }
`;

export const MaskContainerBottom = tw.div`
  curve-mask-inverted
  inverted
  overflow-hidden
`;

export const Logo = styled.div`
  width: 192px;
  height: 86px;
  @media (min-width: 768px) {
    width: 310px;
    height: 140px;
  }
`;
export const LogoStyle =
  'object-contain absolute transform -translate-x-2/4 -translate-y-2/4 left-1/2 top-1/2 md:bg-top md:transform-none md:left-14 md:top-14 md:mt-14 w-[192px] h-[86p] md:w-[310px] md:h-[140px]';
export const BannerContainer = tw.div`
  relative
  flex
  w-full
  overflow-x-hidden
  overflow-y-hidden
`;

export const MobileBannerContainer = styled.div`
  width: 100%;
  height: 340px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DesktopBannerContainer = styled.div`
  width: 100%;
  height: 640px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SubbrandTitle = tw(Title)`
  mt-30
  mb-3
  text-xl
  md:text-2xl
`;

export const ButtonWrapper = tw.div`
  lg:-ml-12
  -mt-8
`;

export const InfoWrapper = tw.div`
  flex
  flex-col
  justify-center
  h-full
  items-center
  lg:items-start
`;

export const CarouselCard = styled.div`
  margin-right: 15px;
  min-width: 98%;
  @media (min-width: 768px) {
    min-width: 32%;
  }
`;
CarouselCard.displayName = 'CarouselCard';
