import React from 'react';
import clsx from 'clsx';

export const Curve = ({ type, className = '' }: { type: 'top' | 'bottom'; className?: string }) => (
  <div
    className={clsx(
      'absolute z-[1]',
      type === 'top' ? 'rounded-b-[100%]' : 'bottom-0 rounded-t-[100%]',
      className,
    )}
  />
);
